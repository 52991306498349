import React, { FunctionComponent } from "react";
import Img, { CloudimageProvider } from "react-cloudimage-responsive-blur-hash";

import { Product } from "src/shared/responses";
import { formattedCurrency } from "src/admin/utils";

const cloudimageConfig = {
  doNotReplaceURL: true,
};

interface Props {
  product: Product;
}

const ProductCard: FunctionComponent<Props> = ({ product }) => {
  const {
    blurhash,
    featuredImageRegularSrc,
    listingUrl,
    shortDescription,
    title,
    categoryName,
    minPrice: { amount, currencyCode },
  } = product.attributes;

  return (
    <div className="w-full p-3 sm:w-1/2 md:w-1/3 md:p-5">
      <a href={listingUrl}>
        <div className="card">
          <div className="h-16 relative">
            <span className="absolute left-0 top-0 py-3 ml-5 text-gray-500 uppercase">
              {categoryName}
            </span>
            <span className="absolute top-0 right-0 px-6 py-3 bg-red text-white font-semibold hover:bg-red-dark">
              {formattedCurrency(amount, currencyCode)}
            </span>
          </div>
          <div className="px-5">
            <CloudimageProvider config={cloudimageConfig}>
              <Img src={featuredImageRegularSrc} alt={title} ratio={1} blurhash={blurhash} />
            </CloudimageProvider>
          </div>
          <div className="px-6 py-4 text-left">
            <div className="font-bold text-xl text-red mt-4 mb-5">{title}</div>
            <p className="text-gray-700">{shortDescription}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ProductCard;

import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";

import { Product } from "src/shared/responses";
import ProductCard from "src/application/components/ProductCard";
import * as Api from "src/application/api";

const HomepageProducts = () => {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    Api.fetchFeaturedProducts().then((data) => {
      setProducts(data.data);
    });
  }, []);

  return (
    <div className="flex flex-wrap -mx-3 md:-mx-5">
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};

export default hot(HomepageProducts);

import React, { FunctionComponent } from "react";
import Img, { CloudimageProvider } from "react-cloudimage-responsive-blur-hash";

import { Shop } from "src/shared/responses";

const cloudimageConfig = {
  doNotReplaceURL: true,
};

interface Props {
  listing: Shop;
}

const ListingCard: FunctionComponent<Props> = ({ listing }) => {
  const { blurhash, name, logoUrl, categoryName, description, listingUrl } = listing.attributes;

  return (
    <div className="w-full p-3 sm:w-1/2 md:w-1/4 md:p-5">
      <a href={listingUrl}>
        <div className="card relative">
          <div className="p-4">
            <CloudimageProvider config={cloudimageConfig}>
              <Img src={logoUrl} alt={name} ratio={1} blurhash={blurhash} />
            </CloudimageProvider>
          </div>
          <div className="px-6 py-4">
            <div className="font-bold text-xl text-red mb-3">{name}</div>
            <div className="mb-5">
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">
                {categoryName}
              </span>
            </div>
            <p className="text-gray-700 text-base">{description}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ListingCard;

import fetch from "unfetch";

import { ProductsResponse, ShopsResponse } from "src/shared/responses";

const baseURL = "/api/v1/public";

export const fetchFeaturedProducts = () =>
  fetch(`${baseURL}/products`).then<ProductsResponse>((response) =>
    response.json()
  );

export const fetchDirectoryListings = () =>
  fetch(`${baseURL}/directory`).then<ShopsResponse>((response) =>
    response.json()
  );

import { UploadResult } from "@uppy/core";
import { Product } from "src/shared/responses";
import snakeCase from "lodash/snakeCase";

export const uploadedFileData = (files: UploadResult): ImageUpload[] =>
  files.successful.map((file) => ({
    id: file.uploadURL.match(/\/cache\/([^?]+)/)[1], // extract key without prefix
    storage: "cache",
    metadata: {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    },
  }));

export const formattedCurrency = (amount: string, currency: string): string => {
  const sum = parseInt(amount, 10) / 100;
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "currency",
    currency,
  });

  return formatter.format(sum);
};

const maxListingsCount = 4;

export const availableListingsCount = (products: Product[]): number =>
  maxListingsCount - products.length;

export const transformPayloadKeys = (object: object) => {
  const result = {};

  Object.keys(object).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      result[snakeCase(key)] = object[key];
    }
  });

  return result;
};

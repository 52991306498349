import React, { useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";

import { Shop } from "src/shared/responses";
import ListingCard from "src/application/components/ListingCard";
import Loading from "src/application/components/Loading";
import * as Api from "src/application/api";

const Directory = () => {
  const [listings, setListings] = useState<Shop[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    Api.fetchDirectoryListings().then((data) => {
      setListings(data.data);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <Loading />;

  return (
    <div className="flex flex-wrap -mx-3 md:-mx-5">
      {listings.map((listing) => (
        <ListingCard key={listing.id} listing={listing} />
      ))}
    </div>
  );
};

export default hot(Directory);
